import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  borderColor: "borderGray",
  _hover: {
    borderColor: "borderGray"
  },
  color: "gray.400",
  _placeholder: {
    color: "gray.400"
  }
});

export const textareaTheme = defineStyleConfig({ baseStyle });
