import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./routes";
import { Box } from "@chakra-ui/react";

export const App = () => {
  return (
    <Box backgroundColor="bgColor" color="white" fontFamily="body" minH="100vh">
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Box>
  );
};
