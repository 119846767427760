import { Agent } from "interface/agents";
import { IClientsResponse } from "interface/clients";
import { IUserObject, IUsers } from "interface/users";
import React from "react";

export type UserProviderValues = {
  userInfo: IUsers;
  userObject: IUserObject;
  storeUserInfo: (value: IUsers) => void;
  storeUserObject: (value: IUserObject) => void;
  agentInfo: Agent;
  storeAgentInfo: (v: Agent) => void;
  clientInfo: IClientsResponse;
  storeClientInfo: (v: IClientsResponse) => void;
};

const initState = {
  userInfo: {} as IUsers,
  userObject: {} as IUserObject,
  storeUserInfo: (value: IUsers) => void value,
  storeUserObject: (value: IUserObject) => void value,
  agentInfo: {} as Agent,
  storeAgentInfo: (value: Agent) => void value,
  clientInfo: {} as IClientsResponse,
  storeClientInfo: (value: IClientsResponse) => void value
};

const UserContext = React.createContext<UserProviderValues>(initState);
export const UserProvider = UserContext.Provider;

type Props = {
  children?: React.ReactNode;
};

export const UserProviderContainer: React.FC<Props> = ({ children }) => {
  const [userInfo, setUserInfo] = React.useState<IUsers>(initState.userInfo);

  const [userObject, setUserObject] = React.useState<IUserObject>(
    initState.userObject
  );

  const [agentInfo, setAgentInfo] = React.useState<Agent>(initState.agentInfo);

  const [clientInfo, setClientInfo] = React.useState<IClientsResponse>(
    initState.clientInfo
  );

  const storeUserInfo = (value: IUsers) => {
    setUserInfo(value);
  };

  const storeUserObject = (value: IUserObject) => {
    setUserObject(value);
  };

  const storeAgentInfo = (value: Agent) => {
    setAgentInfo(value);
  };

  const storeClientInfo = (value: IClientsResponse) => {
    setClientInfo(value);
  };

  return (
    <UserProvider
      value={{
        userObject,
        userInfo,
        storeUserInfo,
        storeUserObject,
        agentInfo,
        storeAgentInfo,
        clientInfo,
        storeClientInfo
      }}
    >
      {children}
    </UserProvider>
  );
};

export default UserContext;
