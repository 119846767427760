import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    color: "bgDarkButton",
    _placeholder: {
      color: "gray.400"
    },
    caretColor: "#FFCD2C",
    _valid: {
      borderColor: "bgDarkButton"
    }
  },
  baseStyle: {
    borderColor: "borderGray",
    _hover: {
      borderColor: "primary"
    },
    _valid: {
      borderColor: "bgDarkButton"
    },
    caretColor: "#FFCD2C"
  },

  variants: {
    // Add variant to customize the cursor color
    flushed: {
      field: {
        caretColor: "#FFCD2C",
        _valid: {
          borderColor: "bgDarkButton"
        },
        "&::placeholder": {
          // Customize the placeholder color
          color: "secondary"
        },
        "&::-webkit-input-placeholder": {
          // Customize the placeholder color for webkit browsers
          color: "secondary"
        },
        "&:-moz-placeholder": {
          // Customize the placeholder color for Firefox
          color: "secondary"
        },
        "&::-moz-placeholder": {
          // Customize the placeholder color for old versions of Firefox
          color: "secondary"
        },
        "&:-ms-input-placeholder": {
          // Customize the placeholder color for Internet Explorer/Edge
          color: "secondary"
        },
        "&::selection": {
          // Customize the selection color
          color: "secondary",
          backgroundColor: "secondary"
        },
        "&::-moz-selection": {
          // Customize the selection color for Firefox
          color: "secondary",
          backgroundColor: "secondary"
        },
        "&::-webkit-selection": {
          // Customize the selection color for webkit browsers
          color: "secondary",
          backgroundColor: "secondary"
        },
        "&::after, &::before": {
          // Change the cursor color when typing
          borderColor: "your_cursor_color"
        }
      }
    }
  }
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
