import { KeyValuePair, ObjectKeyValuePair } from "interface/select";

export const loginKey = "_USR-TK_";
export const refreshKey = "_RFH-TK_";
export const userKey = "_USR_";
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "";

export const WalletCreationSlug: { name: string; value: string }[] = [
  { name: "Vostro", value: "ngn-vostro" },
  { name: "Money Order Fees", value: "ngn-money-order-fees" },
  { name: "Money Order", value: "ngn-money-orders" }
];

export const GenderList: KeyValuePair[] = [
  { name: "Male", id: "Male" },
  { name: "Female", id: "Female" }
];

export const UserGenderList: ObjectKeyValuePair[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" }
];

export const BRANCH_OPERATIONS_TABS = {
  BRANCH_AND_REQUESTS: "Branches and requests",
  AGENTS_RECONCIALIATION_REQUEST: "Agents reconciliation request"
};

export const FEE_TYPES: ObjectKeyValuePair[] = [
  { label: "Flat", value: "flat" },
  { label: "Percentage", value: "percentage" },
  { label: "Range", value: "range" }
];

export const TRANSACTION_TYPES: ObjectKeyValuePair[] = [
  { label: "Bank Transfer", value: "bank-transfer" },
  { label: "Money Order", value: "money-order-create" }
];

export const EMS_POSTAL_SERVICE_TYPES_OPTIONS: ObjectKeyValuePair[] = [
  { label: "Ems", value: "ems" },
  { label: "Airpost", value: "airpost" }
];

export const PARCEL_POSTAL_SERVICE_TYPES_OPTIONS: ObjectKeyValuePair[] = [
  { label: "Parcel", value: "parcel" }
];

export const POSTAL_SERVICE_TYPE_OPTIONS: ObjectKeyValuePair[] = [
  { label: "Ems", value: "ems" },
  { label: "Parcel", value: "parcel" }
];

export const REPORT_TABS_CONSTANTS = {
  TRANSACTIONS: "transactions",
  SETTLEMENTS: "settelents",
  MONEY_ORDER: "money_order",
  EBS_MONEY_ORDER: "ebs_money_order",
  AUDIT_LOG: "audit_log",
  POSTAL_SERVICE: "postal_service"
};

export const REPORT_TABS: { text: string; value: string }[] = [
  { text: "Transactions", value: REPORT_TABS_CONSTANTS.TRANSACTIONS },
  { text: "Money Order", value: REPORT_TABS_CONSTANTS.MONEY_ORDER },
  { text: "Settlements", value: REPORT_TABS_CONSTANTS.SETTLEMENTS },
  { text: "Ebs Money Order", value: REPORT_TABS_CONSTANTS.EBS_MONEY_ORDER },
  { text: "Audit Log", value: REPORT_TABS_CONSTANTS.AUDIT_LOG },
  { text: "Postal Service", value: REPORT_TABS_CONSTANTS.POSTAL_SERVICE }
];
