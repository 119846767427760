import { extendTheme } from "@chakra-ui/react";
import { inputTheme } from "components/InputComponent/inputTheme";
import { textareaTheme } from "components/TextAreaComponent/textAreaTheme";

const theme = extendTheme({
  colors: {
    primary: "#1128BB",
    bgColor: "#F7F7F8",
    gray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C"
    },
    bgDarkButton: "#030303",
    borderGray: "#E2E8F0",
    secondary: "#FFCD2C",
    dark: { 300: "#9E9E9C", 400: "#595959", 500: "#232323" },
    bgDark: "#303030",
    grayBg: "#E2E8F0",
    black: "#000000",
    badgeBg: "#606060",
    bgAvatar: "#6E72FF",
    green: { 100: "#C6F6D5", 700: "#276749" },
    red: { 100: "#FED7D7", 50: "#FFF5F5", 500: "#E53E3E", 600: "#C53030" },
    yellow: { 100: "#ffe900", 500: "#D9B200" }
  },
  fonts: {
    body: "Inter, sans-serif"
  },
  styles: {
    global: {
      ".activeLink": {
        backgroundColor: "#FFCD2C",
        borderTopLeftRadius: "0.5rem",
        borderTopRightRadius: "0.5rem",
        width: "100%",
        height: "3px",
        display: "block"
      },
      ".dotIcon": {
        width: "30px",
        height: "30px"
      },
      ".inActiveLink": {
        backgroundColor: "#1128BB",
        borderTopLeftRadius: "40px",
        borderTopRightRadius: "40px",
        width: "100%",
        height: "3px",
        display: "block"
      },
      ".navlinks": {
        margin: "auto"
      },
      ".tableContainer": {
        overflowX: "auto"
      },
      ".phoneInput": {
        width: "100% !important",
        height: "3rem",
        color: "bgDarkButton !important",
        _placeholder: {
          color: "#A0AEC0 !important"
        },
        borderColor: "inherit !important",
        caretColor: "#FFCD2C !important",
        _focus: {
          borderColor: "#1128BB !important"
        }
      },
      "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":
        {
          "-webkit-box-shadow": "0 0 0px 1000px rgba(0, 0, 0, 0, 0) inset",
          "box-shadow": "0 0 0px 1000px rgba(0, 0, 0, 0, 0) inset",
          transition: "background-color 5000s ease-in-out 0s"
        },
      "input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button":
        {
          "-webkit-appearance": "none",
          margin: "0"
        }
    }
  },
  breakpoints: {
    xxl: "1440px",
    xlg: "1200px",
    lg: "1025px",
    md: "769px",
    xs: "450px",
    xxs: "340px",
    base: "0px"
  },
  components: {
    Input: inputTheme,
    Textarea: textareaTheme
  }
});

export default theme;
