import React from "react";
import { loginKey } from "utils/constants";
import { componentLoader, lazyWithRetry } from "utils/import-utils";
import storage from "utils/storage";

const AuthenticatedApp = lazyWithRetry(() =>
  componentLoader(() => import("layout/AppLayout/index"))
);

const UnauthenticatedLayout = lazyWithRetry(() =>
  componentLoader(() => import("layout/Unauthenticated/index"))
);

const RouteLayout = () => {
  const token = storage.get(loginKey);
  return <div>{token ? <AuthenticatedApp /> : <UnauthenticatedLayout />}</div>;
};

export default RouteLayout;
