import React from "react";
import RouteLayout from "layout";

const AppRouter = () => {
  return (
    <div>
      <RouteLayout />
    </div>
  );
};

export default AppRouter;
